<template>
  <div class="feature-section">
    <h2>Powerful Features to Boost Your Job Search</h2>
    <div class="feature-grid">
      <div class="feature-item">
        <div class="icon-bg ai-bg">
          <i class="fas fa-robot"></i>
        </div>
        <h3>AI-Powered Assistance</h3>
        <p>Tailored applications with advanced AI analysis</p>
      </div>
      <div class="feature-item">
        <div class="icon-bg time-bg">
          <i class="fas fa-clock"></i>
        </div>
        <h3>Time-Saving Tools</h3>
        <p>Generate content in minutes, not hours</p>
      </div>
      <div class="feature-item">
        <div class="icon-bg success-bg">
          <i class="fas fa-chart-line"></i>
        </div>
        <h3>3x Success Rate</h3>
        <p>Higher interview invitation rate</p>
      </div>
      <div class="feature-item">
        <div class="icon-bg secure-bg">
          <i class="fas fa-lock"></i>
        </div>
        <h3>Secure & Private</h3>
        <p>Your data remains confidential</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.feature-section {
  background: linear-gradient(to bottom, #f0f4ff, #ffffff);
  padding: 3rem 1rem;
  margin-top: 3rem;
}

h2 {
  text-align: center;
  color: #333;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.feature-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.feature-item {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1.5rem;
  width: 200px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
}

.icon-bg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 1rem;
}

.icon-bg i {
  font-size: 1.5rem;
  color: #ffffff;
}

.ai-bg { background-color: #7e57c2; }
.time-bg { background-color: #4caf50; }
.success-bg { background-color: #ff9800; }
.secure-bg { background-color: #2196f3; }

h3 {
  color: #333;
  margin-bottom: 0.5rem;
}

p {
  color: #666;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .feature-grid {
    flex-direction: column;
    align-items: center;
  }
  
  .feature-item {
    width: 100%;
    max-width: 300px;
  }
}
</style>