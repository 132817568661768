let apiUrl;
let stripePublishableKey;

if (process.env.NODE_ENV === 'development') {

  apiUrl = 'http://127.0.0.1:5000';
  stripePublishableKey= process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY_LOCAL;

} else {
  
    apiUrl = 'https://cover-letter-backend-xgqsuwrgcq-uc.a.run.app'; 
    stripePublishableKey= process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY_PROD;

}

export default {
  apiUrl,
  stripePublishableKey,
  firebase: {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  }
};