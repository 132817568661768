<!-- SocialProof.vue -->
<template>
  <div class="social-proof">
    <h2>Success Stories</h2>
    <div class="testimonial-carousel">
      <div v-for="(testimonial, index) in testimonials" :key="index" class="testimonial">
        <img :src="testimonial.avatar" :alt="testimonial.name" class="avatar">
        <h3 class="name">{{ testimonial.name }}</h3>
        <p class="quote">"{{ testimonial.quote }}"</p>
        <p class="author">{{ testimonial.position }} at {{ testimonial.company }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialProof',
  data() {
    return {
      testimonials: [
        {
          name: "Paschalis Dimitriou",
          position: "Software Engineer",
          company: "CH Media",
          quote: "This AI-powered tool helped me craft the perfect application. I landed my dream job!",
          avatar: require("@/assets/avatars/pd-avatar.jpeg")
        },
             {
          name: "Pau Belda Sempere",
          position: "Team Leader Global Marketing",
          company: "Ingersoll Rand",
          quote: "The cover letter generated by this tool was impressive. It definitely helped me stand out.",
          avatar: require("@/assets/avatars/pbs-avatar.jpeg")
        },
             {
          name: "Antonio Jesus Canovas Vives",
          position: "Senior Product Owner",
          company: "Properti",
          quote: "I was skeptical at first, but this tool truly understands what employers are looking for.",
          avatar: require("@/assets/avatars/ajcv-avatar.jpeg")
        },
        
      ]
    }
  }
}
</script>

<style scoped>
.social-proof {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.testimonial-carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 1rem;
}

.testimonial {
  min-width: 300px;
  max-width: 100%;
  scroll-snap-align: start;
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: center;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto 1rem;
}

.name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.quote {
  font-style: italic;
  margin-bottom: 1rem;
}

.author {
  font-weight: bold;
}
</style>