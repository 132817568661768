<!-- ProcessExplanation.vue -->
<template>
  <div class="process-explanation">
    <h2>How It Works</h2>
    <div class="process-steps">
      <div v-for="(step, index) in steps" :key="index" class="step">
        <div class="step-icon">
          <img :src="step.icon" :alt="step.title">
        </div>
        <h3>{{ step.title }}</h3>
        <p>{{ step.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProcessExplanation',
  data() {
    return {
      steps: [
        {
          title: "Upload CV",
          description: "Start by uploading your current CV in PDF format.",
          icon: require("@/assets/processExplanation/upload.png")
        },
        {
          title: "Provide Job Details",
          description: "Enter the job description and any specific application questions.",
          icon: require("@/assets/processExplanation/job-details.png")
        },
        {
          title: "AI Analysis",
          description: "Our AI analyzes your CV and the job requirements to generate tailored content.",
          icon: require("@/assets/processExplanation/analysis.png")
        },
        {
          title: "Generate Application",
          description: "Receive a customized cover letter and suggested answers to application questions.",
          icon: require("@/assets/processExplanation/checked.png")
        }
      ]
    }
  }
}
</script>

<style scoped>
.process-explanation {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.process-steps {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}

.step {
  flex: 1;
  min-width: 200px;
  text-align: center;
}

.step-icon {
  width: 80px;
  height: 80px;
  margin: 0 auto 1rem;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-icon img {
  width: 50%;
  height: 50%;
  object-fit: contain;
}

h3 {
  margin-bottom: 0.5rem;
}
</style>