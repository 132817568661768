<!-- App.vue -->
<template>
  <div id="app">
    <header>
      <div class="header-content">
        <div class="logo">Job Application Assistant</div>
        <div v-if="!user" class="auth-buttons">
          <button @click="signIn" class="login-btn">Login</button>
          <button @click="signIn" class="signup-btn">Sign up</button>
        </div>
        <div v-else class="user-info">
          <span>Welcome, {{ user.displayName }}</span>
          <button @click="signOut" class="logout-btn">Logout</button>
        </div>
      </div>
    </header>

    <main class="container">
      <div class="hero-section">
        <h1>Land Your Dream Job with AI-Powered Applications</h1>
        <p>Craft winning applications and land your dream job faster than ever.</p>
      </div>
      <ProcessExplanation />
      <ApplicationProcess />
      <SocialProof />
      <FeatureSection />
    </main>
    
    <PricingBanner />
  </div>
</template>

<script>
import { auth } from './firebase'
import { onAuthStateChanged, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth'
import PricingBanner from './components/pricingBanner.vue'
import SocialProof from './components/socialProof.vue'
import ProcessExplanation from './components/processExplanation.vue'
import ApplicationProcess from './components/applicationProcess.vue'
import FeatureSection from './components/featureSection.vue'


export default {
  name: 'App',
  components: {
    PricingBanner,
    SocialProof,
    ProcessExplanation,
    ApplicationProcess,
    FeatureSection
  },
  data() {
    return {
      user: null,
    }
  },
  mounted() {
    onAuthStateChanged(auth, (user) => {
      this.user = user;
    });
  },
  methods: {
    async signIn() {
      const provider = new GoogleAuthProvider();
      try {
        await signInWithPopup(auth, provider);
      } catch (error) {
        console.error("Error signing in with Google", error);
      }
    },
    async signOut() {
      try {
        await signOut(auth);
      } catch (error) {
        console.error("Error signing out", error);
      }
    },
  }
}
</script>

<style>
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  margin: 0;
  padding: 0;
  background-color: #f4f7f9;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 1rem 2rem;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #4a4a4a;
}

.auth-buttons {
  display: flex;
  gap: 1rem;
}

.login-btn, .signup-btn, .logout-btn {
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-btn {
  color: #4a4a4a;
  border: 1px solid #4a4a4a;
}

.signup-btn {
  background-color: #7e57c2;
  color: white;
  border: none;
}

.logout-btn {
  background-color: #f44336;
  color: white;
  border: none;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

main.container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.hero-section {
  text-align: center;
  margin-bottom: 2rem;
}

.hero-section h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.hero-section p {
  font-size: 1.2rem;
  color: #666;
}

@media (max-width: 600px) {
  main.container {
    padding: 1rem;
  }

  .hero-section h1 {
    font-size: 2rem;
  }

  .hero-section p {
    font-size: 1rem;
  }
}
</style>