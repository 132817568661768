<template>
  <div class="pricing-banner">
    <div class="pricing-tier" :class="{ 'selected': selectedTier === 'free' }">
      <h3>Free</h3>
      <p class="price">$0/month</p>
      <ul>
        <li>5 AI-generated cover letters per day</li>
        <li>Basic job application advice</li>
        <li>Standard support</li>
      </ul>
      <button @click="selectTier('free')" :disabled="selectedTier === 'free'">Free Plan</button>
    </div>
    <div class="pricing-tier" :class="{ 'selected': selectedTier === 'professional' }">
      <h3>Professional</h3>
      <p class="price">$15/month</p>
      <ul>
        <li>50 AI-generated cover letters</li>
        <li>Advanced job application insights</li>
        <li>Priority support</li>
      </ul>
      <button @click="selectTier('professional')" :disabled="selectedTier === 'professional'">Upgrade Now</button>
    </div>
    <div class="pricing-tier" :class="{ 'selected': selectedTier === 'business' }">
      <h3>Business</h3>
      <p class="price">Custom</p>
      <ul>
        <li>Enterprise-level solutions</li>
        <li>Tailored AI integration</li>
        <li>Dedicated account manager</li>
      </ul>
      <button @click="contactUs">Contact Us</button>
    </div>

    <!-- Loading Indicator -->
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>

    <!-- Error Message -->
    <div v-if="error" class="error-message">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import config from '../config';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { signInWithGoogle } from '../utils/auth';


export default {
  name: 'PricingBanner',
  data() {
    return {
      selectedTier: 'free',
      stripe: null,
      loading: false,
      error: null,
      user: null,
      subscriptionStatus: null
    }
  },
  async mounted() {
    this.stripe = await loadStripe(config.stripePublishableKey);
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      this.user = user;
      if (user) {
        this.fetchSubscriptionStatus();
      }
    });
  },
  methods: {
    async fetchSubscriptionStatus() {
      try {
        const idToken = await this.user.getIdToken();
        const response = await fetch(`${config.apiUrl}/subscription-status`, {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        });
        const data = await response.json();
        this.subscriptionStatus = data;
        this.selectedTier = data.tier;
      } catch (error) {
        console.error('Error fetching subscription status:', error);
      }
    },
    async selectTier(tier) {
      if (tier === 'professional') {
        if (!this.user) {
          await signInWithGoogle();
          return;
        }
        if (this.subscriptionStatus && this.subscriptionStatus.status === 'active') {
          this.error = "You already have an active subscription.";
          return;
        }
        await this.proceedWithCheckout();
      } else {
        this.selectedTier = tier;
      }
    },
    async proceedWithCheckout() {
      this.loading = true;
      this.error = null;
      try {
        if (!this.user) {
          throw new Error('User not authenticated');
        }

        const idToken = await this.user.getIdToken();
        const email = this.user.email;

        const response = await fetch(`${config.apiUrl}/create-checkout-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify({
            priceId: config.stripePriceIdProfessional,
            email: email
          }),
        });
        
        if (!response.ok) {
          throw new Error('Failed to create checkout session');
        }

        const { sessionId } = await response.json();
        
        const result = await this.stripe.redirectToCheckout({ sessionId });
        
        if (result.error) {
          throw new Error(result.error.message);
        }
      } catch (error) {
        console.error('Error in checkout process:', error);
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    contactUs() {
      // Implement contact functionality for Business tier
      console.log('Contacting for Business tier');
    }
  }
}
</script>

<style scoped>
.pricing-banner {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
  margin-top: 30px;
}

.pricing-tier {
  flex: 1;
  max-width: 300px;
  padding: 20px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.pricing-tier:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.pricing-tier.selected {
  border-color: #7e57c2;
  box-shadow: 0 0 15px rgba(126, 87, 194, 0.3);
}

.pricing-tier h3 {
  color: #333;
  margin-bottom: 10px;
}

.price {
  font-size: 1.5em;
  font-weight: bold;
  color: #7e57c2;
  margin-bottom: 15px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

li {
  margin-bottom: 10px;
}

button {
  background-color: #7e57c2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover:not(:disabled) {
  background-color: #6a4caf;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

button:disabled {
  background-color: #d1c4e9;
  cursor: not-allowed;
}
</style>