<!-- components/ApplicationProcess.vue -->
<template>
  <div class="application-process">
    <div class="progress-bar">
      <div class="progress" :style="{ width: progressPercentage + '%' }"></div>
    </div>

    <section v-if="currentStep === 1" class="upload-section">
      <h2>1. Upload your CV 📃</h2>
      <div 
        class="dropzone" 
        @drop.prevent="handleDrop" 
        @dragover.prevent 
        @dragenter.prevent
      >
        <p>Drag your CV here or</p>
        <label for="cv-upload" class="file-upload-btn">Choose File</label>
        <input id="cv-upload" type="file" @change="handleFileUpload" accept=".pdf" style="display:none;">
      </div>
      <span v-if="cvFile">{{ cvFile.name }}</span>
      <button @click="nextStep" :disabled="!cvFile" class="action-btn">Next</button>
    </section>

    <section v-if="currentStep === 2" class="job-description-section">
      <h2>2. Paste the job description below 👇</h2>
      <textarea 
        v-model="jobDescription" 
        rows="8" 
        placeholder="Include the Role, Company name, and Job responsibilities for better results"
        @input="analyzeJobDescription"
      ></textarea>
      <div class="job-description-feedback" v-if="jobDescriptionFeedback">
        <p>{{ jobDescriptionFeedback }}</p>
      </div>
      <button @click="showJobDescriptionExample" class="example-btn">See Example</button>
      <div class="nav-buttons">
        <button @click="prevStep" class="action-btn">Back</button>
        <button @click="nextStep" :disabled="!jobDescription" class="action-btn">Next</button>
      </div>
    </section>

    <section v-if="currentStep === 3" class="question-section">
      <h2>3. Fill in job application questions 🎙️</h2>
      <input 
        v-model="applicationQuestion" 
        type="text" 
        placeholder="Enter your Application question and get suggestions. e.g: Why should we hire you?"
      >
      <button @click="consult" class="action-btn" :disabled="isLoading">Consult</button>
      <div class="nav-buttons">
        <button @click="prevStep" class="action-btn">Back</button>
        <button @click="nextStep" :disabled="!applicationQuestion" class="action-btn">Next</button>
      </div>
    </section>

    <section v-if="currentStep === 4" class="generate-section">
      <h2>4. Create your cover letter ✉️</h2>
      <select v-model="coverLetterTone" class="tone-select">
        <option value="professional">Professional</option>
        <option value="friendly">Friendly</option>
        <option value="confident">Confident</option>
      </select>
      <button @click="generate" class="action-btn" :disabled="isLoading">Generate</button>
      <div class="nav-buttons">
        <button @click="prevStep" class="action-btn">Back</button>
      </div>
    </section>

    <div v-if="isLoading" class="loading-container">
      <div class="paper-airplane"></div>
      <p>Crafting your perfect application...</p>
    </div>

    <section v-if="result && !isLoading" class="result-section">
      <h3>{{ resultTitle }}:</h3>
      <div class="result-content" contenteditable="true" @input="handleEdit">
        {{ result }}
      </div>
      <div class="result-actions">
        <button @click="saveResult" class="action-btn">Save</button>
        <button @click="regenerateWithTone" class="action-btn">Regenerate</button>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import config from '../config';
import { auth } from '../firebase';
import { getIdToken } from 'firebase/auth';
import { jsPDF } from 'jspdf';
import { signInWithGoogle } from '../utils/auth';

export default {
  name: 'ApplicationProcess',
  data() {
    return {
      cvFile: null,
      jobDescription: '',
      applicationQuestion: '',
      result: '',
      isLoading: false,
      resultTitle: '',
      currentStep: 1,
      jobDescriptionFeedback: '',
      coverLetterTone: 'professional',
    }
  },
  computed: {
    progressPercentage() {
      return (this.currentStep / 4) * 100;
    },
    formattedResult() {
      return this.result.replace(/\n/g, '<br>');
    }
  },
  methods: {
    handleFileUpload(event) {
      this.cvFile = event.target.files[0];
    },
    async consult() {
      if (!this.validateInputs()) return;
      
      this.isLoading = true;
      this.result = '';
      this.resultTitle = 'Consultation Answer';
      
      const formData = new FormData();
      formData.append('job_description', this.jobDescription);
      formData.append('cv', this.cvFile);
      formData.append('question', this.applicationQuestion);
      
      const { data, error } = await this.makeAuthenticatedRequest(`${config.apiUrl}/generate-question-answer`, formData);
      
      if (error) {
        this.result = error;
      } else if (data) {
        this.result = this.formatResponse(data.answer);
      }
      
      this.isLoading = false;
    },
    async generate() {
      if (!this.validateInputs()) return;
      if (!auth.currentUser) {
        try {
          await signInWithGoogle();
        } catch (error) {
          this.result = "Failed to sign in. Please try again.";
          return;
        }
      }
      this.isLoading = true;
      this.result = '';
      this.resultTitle = 'Cover Letter';
      
      const formData = new FormData();
      formData.append('job_description', this.jobDescription);
      formData.append('cv', this.cvFile);
      
      const { data, error } = await this.makeAuthenticatedRequest(`${config.apiUrl}/generate-cover-letter`, formData);
      
      if (error) {
        this.result = error;
      } else if (data) {
        this.result = this.formatResponse(data.cover_letter);
      }
      
      this.isLoading = false;
    },
    validateInputs() {
      if (!this.cvFile) {
        alert('Please upload a CV file.');
        return false;
      }
      if (!this.jobDescription) {
        alert('Please enter a job description.');
        return false;
      }
      return true;
    },
    formatResponse(text) {
      return text.replace(/^"|"$/g, '').replace(/\\n/g, '\n');
    },
    async makeAuthenticatedRequest(url, formData) {
      const headers = { 'Content-Type': 'multipart/form-data' };
      if (auth.currentUser) {
        const token = await getIdToken(auth.currentUser);
        headers['Authorization'] = `Bearer ${token}`;
      }
      try {
        const response = await axios.post(url, formData, { headers });
        return { data: response.data, error: null };
      } catch (error) {
        if (error.response && error.response.status === 429) {
          return { 
            data: null, 
            error: "🚀 Wow! You've maxed out your daily AI power! 🎉\n\nWhy not unlock unlimited potential? \nUpgrade now for non-stop AI support and take your job search to the next level! 💼\n\nOr, if you prefer, come back tomorrow for a fresh start!"
          };
        }
        console.error('Error during API request:', error);
        return { data: null, error: "An error occurred. Please try again." };
      }
    },
    nextStep() {
      if (this.currentStep < 4) this.currentStep++;
    },
    prevStep() {
      if (this.currentStep > 1) this.currentStep--;
    },
    handleDrop(e) {
      const file = e.dataTransfer.files[0];
      this.handleFile(file);
    },
    handleFile(file) {
      if (file.type === 'application/pdf') {
        this.cvFile = file;
      } else {
        alert('Please upload a PDF file');
      }
    },
    analyzeJobDescription() {
      if (this.jobDescription.length < 50) {
        this.jobDescriptionFeedback = "Consider adding more details for better results.";
      } else if (this.jobDescription.length > 500) {
        this.jobDescriptionFeedback = "Great detail! Our AI will have plenty to work with.";
      } else {
        this.jobDescriptionFeedback = "Good job description length.";
      }
    },
    showJobDescriptionExample() {
      alert("Example Job Description: \n\nSoftware Engineer at TechCorp\n\nWe're seeking a skilled Software Engineer to join our innovative team. You'll be responsible for developing high-quality software solutions, collaborating with cross-functional teams, and contributing to the full software development lifecycle. The ideal candidate has strong problem-solving skills, experience with modern programming languages, and a passion for creating efficient, scalable code.");
    },
    handleEdit(e) {
      this.result = e.target.innerText;
    },
    saveResult() {
      const doc = new jsPDF();
      const splitText = doc.splitTextToSize(this.result, 180);
      doc.text(splitText, 15, 15);
      doc.save('cover_letter.pdf');
    },
    regenerateWithTone() {
      console.log(`Regenerating with ${this.coverLetterTone} tone...`);
      this.generate();
    }
  }
}
</script>

<style scoped>
.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-bottom: 2rem;
}

.progress {
  height: 100%;
  background-color: #7e57c2;
  border-radius: 5px;
  transition: width 0.3s ease;
}

section {
  margin-bottom: 2rem;
}

h2 {
  margin-bottom: 1rem;
  color: #4a4a4a;
}

textarea, input[type="text"] {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.dropzone {
  max-width: 100%;
  margin: 0 auto 1rem; /* Added bottom margin */
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
  border: 2px dashed #ccc;
  border-radius: 8px;
}

.file-upload-btn, .example-btn, .action-btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #7e57c2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.file-upload-btn:hover, .example-btn:hover, .action-btn:hover {
  background-color: #5e35b1;
}

.result-section {
  background-color: #f9f9f9;
  padding: 1rem;
  border-radius: 4px;
}

.result-content {
  border: 1px solid #ddd;
  padding: 1rem;
  min-height: 200px;
  margin-bottom: 1rem;
  white-space: pre-wrap;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.result-actions {
  display: flex;
  gap: 1rem;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.paper-airplane {
  width: 50px;
  height: 50px;
  background-color: #7e57c2;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
  animation: fly 2s infinite ease-in-out;
}

@keyframes fly {
  0%, 100% { transform: translateY(0) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(5deg); }
}

.nav-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.tone-select {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.job-description-feedback {
  margin-top: 0.5rem;
  font-style: italic;
  color: #666;
}

.upload-section > span {
  display: block;
  margin-bottom: 1rem;
}

.generate-section {
  text-align: center;
}

.generate-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* This creates a small gap between the dropdown and button */
  margin-bottom: 1rem;
}

.tone-select {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-right: 1rem;
}

.action-btn {
  padding: 0.5rem 1rem;
  background-color: #7e57c2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.action-btn:hover {
  background-color: #5e35b1;
}


</style>