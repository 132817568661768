// main.js

import { createApp } from 'vue'
import App from './App.vue'
import './firebase' // Ensure Firebase is initialized

const app = createApp(App)

// If you need Firebase auth globally, you can add it like this:
// import { auth } from './firebase'
// app.config.globalProperties.$auth = auth

app.mount('#app')